import React, { Component } from 'react';
import {ToastContainer} from 'react-toastify';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import logo from './logo.svg';

//internal libraries
//Need a page to view student checker questions and grades
import NavBar from './components/common/navigationbar';
import LoginStudent from './components/authentication/login';
import RegisterStudent from './components/authentication/registerstudent';
import HomePage from './components/general/homepage';
import Tutorials from './components/tutorials/tutorialspage';
import Tutorial from './components/tutorials/tutorialpage';
import NotFound from './components/error_pages/notfound';
import LogOut from './components/authentication/logout';
import {getCurrentUser,getCurrentUserProfile} from './services/students/studentauthen';
import ProtectedRoute from './components/common/protectedRoute';
//TODO these should be behind authentication 

import CreateNewDiscussionResponseForm from './components/student_dashboard/module_discussion/creatediscussionresponseform';
import CourseDiscussions from './components/student_dashboard/module_discussion/coursediscussions';
import DiscussionArea from './components/student_dashboard/module_discussion/discussionarea';
import Dashboard from './components/student_dashboard/dashboard';
import DiscussionResponse from './components/student_dashboard/module_discussion/discussion_response/discussionresponse';
import RecoverPassword from './components/authentication/recoverpassword';
import ContactUs from './components/general/contact';
import CoursesPage from './components/courses/public/coursespage';
import MyCourses from './components/student_dashboard/courses/mycourses';
import CourseModules from './components/student_dashboard/courses/module/coursemodules';
import CourseModuleArea from './components/student_dashboard/courses/module/coursemodulearea';
import GeneralFooter from './components/common/footer/generalfooter';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
//this is another place to find the active and past checker questions
import CheckerQuestionArea from './components/student_dashboard/insclassactivities/checkerquestionarea';
import PastCheckerQuestions from './components/student_dashboard/insclassactivities/pastcheckerquestions';
//this will be called in the In Class Session Page when new question is issued the link will be sent there
//also in the notfiication
import ActiveCheckerQuestion from './components/student_dashboard/insclassactivities/activecheckerquestion';
//gradebook will show all the grades for all the module assessesments for the course

class App extends Component {
//TODO need to create the list of courses and list of tutorials controls to return these
//need to also create the Create and Update method with axios
//calling the api requests
//TODO set up protected routes
//TODO need pages coursediscussions 

  state = {  
    currentProfile:[{
      firstName: '',
       lastName: '',
       emailAddress: '',
       studentId: ''
    }],
    student:[]
  };

  async componentDidMount(){
   const student = getCurrentUser();

   this.setState({student});
   //get the student profile here
   const {data: profile} = await getCurrentUserProfile();
   console.log("Profle");
   
    const currentProfile =[
     {
       firstName: profile.firstName,
       lastName: profile.lastName,
       emailAddress: profile.emailAddress,
       studentId: profile.studentId
     }
   ];
  console.log(currentProfile);
   
   this.setState({currentProfile});
   //show footer when student == null or not signed in
  }

  render() { 
    const {student,currentProfile} = this.state;
    return (  
      <React.Fragment>
        <ToastContainer/>
        
        <NavBar student={student} currentProfile={currentProfile}/>
        <div className="content">
          <Switch>
          <Route path="/tutorials" component={Tutorials}/>
          <Route path="/tutorial/:title" component={Tutorial}/>
          <Route path="/login" component={LoginStudent}/>
          <Route path="/register" component={RegisterStudent}/>
          <Route path="/courses" component={CoursesPage}/>
          
          <ProtectedRoute path="/dashboard" 
          component={Dashboard}
          />
          <ProtectedRoute path="/courseModules/:courseId" component={CourseModules}/>
          <ProtectedRoute path="/discussionresponse/:discussResponseId" component={DiscussionResponse}/> 
          <ProtectedRoute path="/createresponse/:id/:mode" component={CreateNewDiscussionResponseForm}/>
          <ProtectedRoute path="/coursediscussions/:moduleId" component={CourseDiscussions}/>
          <ProtectedRoute path="/discussionarea/:id" component={DiscussionArea}/>
          <ProtectedRoute path="/mycourses" component={MyCourses} currentProfile={currentProfile}/>
          <ProtectedRoute path="/coursemodulearea" component={CourseModuleArea}/>
          <ProtectedRoute path="/checkerquestionarea" component={CheckerQuestionArea}/>
          <ProtectedRoute path="/pastcheckerquestion" component={PastCheckerQuestions}/>
          <ProtectedRoute path="/activecheckerquestion" component={ActiveCheckerQuestion}/>
          <Route path="/recoverpassword" component={RecoverPassword}/>
          <Route path="/contactus" component={ContactUs}/>
          <Route path="/logout" component={LogOut}/>
          <Route path="/" exact  component={HomePage}/>
          <Route path="/notfound" component={NotFound}/>
          <Redirect to="/notfound"/>
          
          </Switch>
        </div>
        {!student && 
          <GeneralFooter/>
        }
      </React.Fragment>
    );
  }
}
 
export default App;