import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import {authLoginStudent,getCurrentUser} from '../../services/students/studentauthen';
import { Redirect ,Link} from 'react-router-dom';


class LoginStudent extends Form {

    state = {
        data:{
            emailAddress: '',
            password: ''
        },
        errors:{
           
        }
    };
    //Joi validation schema
    schema ={
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'edu','edu.jm'] } }).required().label('Email Address'),//use joi in node as well
        password: Joi.string().required().label('Password')
    }
 

    componentDidMount(){
      //  this.emailAddress.current.focus();
    }

    doSubmit = async () =>{
        //login function
        try{
            const {data} = this.state;
            const accountPayLoad ={
                emailAddress: data.emailAddress,
                passwordTxt: data.password
            }
            
            await authLoginStudent(accountPayLoad);

            //get the current location
            const {state} = this.props.location; 
           
            //force a reload of the page
            window.location = state ? state.from.pathname : "/dashboard";

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors ={...this.state.errors};
                errors.emailAddress = ex.response.data;
                //Update the UI
                this.setState({errors});
            }
        }
    }
    render() { 
       //if user logged in
        if(getCurrentUser()) return <Redirect to="/dashboard"/>;
        

        return (  
            <div className="login-area section--padding card-box-shared-body">
            
            
                <div className="card-box-shared-title text-center">
                    <h3 className="widget-title font-size-35">Student - Log In</h3>
                </div>
                    <br/>
                  <form onSubmit={this.handleSubmit} className="contact-form-action"> 
                    <div className="col-lg-12">
                        <div className="input-box">
                        {this.renderTextInputLabel("emailAddress","Email Address","text","form-control","Email Address","label-text",true,"la la-envelope input-icon")}

                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="input-box">
                        {this.renderTextInputLabel("password","Password","password","form-control","Password","label-text",true,"la la-key input-icon")}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <div className="custom-checkbox d-flex justify-content-between">
                                
                                <Link to="/recoverpassword" className="primary-color-2"> Forgot my password?</Link>
                            </div>
                        </div>
                    </div>   
                    <div className="col-lg-12 ">
                        <div className="btn-box">
                        {this.renderButton('Login',"theme-btn")} 
                        </div>
                    </div>
                      <div className="col-lg-12">
                        <p className="mt-4 text-secondary">Don't have an account? <Link to="/register" className="primary-color-2">Register</Link></p>
                      </div>
                  </form>

                
            </div>
        );
    }
}
 
export default LoginStudent;