import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Moment from 'react-moment';

const CourseListCard  = ({timeStamp,question,discussionDetail,mediaUrl,title,discussionId}) => {
    let defaultImage = "https://firebasestorage.googleapis.com/v0/b/vengarreweb-b5979.appspot.com/o/vengarreUserAuthorImages%2FAuthor_Image_155346228884902.png?alt=media&token=4caba676-7e1c-4ceb-b447-7500991d2e88";

    return (   
        

        <div className="col-lg-12">
                <div className="card-item card-list-layout card-preview" data-tooltip-content="#tooltip_content_1" >
                    <div className="card-image">
                        <Link to={`/discussionarea/${discussionId}`} className="card__img"><img src={mediaUrl != null? mediaUrl : defaultImage} alt=""/></Link>
                    </div>
                    <div className="card-content">
                    
                        <h3 className="card__title">
                            <Link to={`/discussionarea/${discussionId}`}>{title}</Link>
                        </h3>
                        <p className="card__label">
                
                            <h2>{question}</h2>
                            
              
                        </p>
                        <p>{discussionDetail}</p>
              
                        <div className="card-action">
                            <ul className="card-duration d-flex justify-content-between align-items-center">
                                <li>
                              
                                </li>
                                <li>
                                <span className="meta__date">
                                    <i className="la la-clock-o"></i> <Moment fromNow>{timeStamp != null?
                            timeStamp : Date().toLocaleString()}</Moment>
                                </span>
                                </li>
                            </ul>
                        </div>
                      
                    </div>
                </div>
        </div>
            
          
           
        );
    }

 
export default CourseListCard;




