import http from '../httpService';
import {apiEndpoint} from '../../constants/config.json';


export function fetchLatestThreeTutorials(){
    const fullApiEndpoint = apiEndpoint + "/tutorials/api/get_latest_three_tutorial_blogs";
    return http.get(fullApiEndpoint);
}

//Need to add pagnination to this website
export function fetchAllTutorials(){
    const fullApiEndpoint = apiEndpoint + "/tutorials/api/get_all_tutorial_blogs";
    return http.get(fullApiEndpoint);
}

export function getTargetTutorialByTitle(title){
    const fullApiEndpoint = apiEndpoint + "/tutorials/api/get_tutorial_by_title/" + title;
    return http.get(fullApiEndpoint);
}

//need a method to return the author profile
export function getAuthorProfileById(id){
    const fullApiEndpoint = apiEndpoint + "/users/api/get_userprofile_by_user_id/" + id;
    return http.get(fullApiEndpoint);
}