import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import 'moment-timezone';
import {fetchTargetDiscussion,fetchStudentResponsesByDiscussionId,createDiscussionResponse} from '../../../services/courses/discussion/discussionService';
import DiscussionCardResponse from '../../common/discussioncards/discussioncardresponse';

let defaultImage = "https://firebasestorage.googleapis.com/v0/b/vengarreweb-b5979.appspot.com/o/vengarreUserAuthorImages%2FAuthor_Image_155346228884902.png?alt=media&token=4caba676-7e1c-4ceb-b447-7500991d2e88";

class DiscussionArea extends Component {
    //TODO need to show the discussion critea
    state = { 
        discussions: [{
            discussionId: '',
            discussionTitle: '',
            discussionQuestion: '',
            mediaUrl: '',
            discussionDetail: ''

        }],
          discussionResponses: [{
            discussResponseId: '',
            discussionId: '',
            studentId: '',
            responseDetail: '',
            mediaUrl: '',
            numOfReplies: 0,
            recordTimeStamp:'',
            recordUpdatedTimeStamp: ''

        }],
        showHelpful: false,//this can be used for survey
        //need to track the students as well
     }
//show all the discussions then click the discussion to show all the sub discussions
    async componentDidMount() {
        //promise will complete in the future or in pending then resolved (success) or rejected if fail
        //wait till the promised results appears then extract the values
        //get data and rename it to courses (the object name)
        //const {data: courses} = await http.get(config.apiEndpoint +'/courses/api/get_course_headers/0/6');
       
       //discussion id to fetch the discussion, critiea and student posts (should only show a post after the student has added their
       //own post)
        console.log(this.props.match.params.id);
        const discussionId = this.props.match.params.id;
        const {data: discussions} = await fetchTargetDiscussion(discussionId);
        console.log(discussions);
        //update the courses by setting the state
        this.setState({discussions});

        //get the student responses
        const {data: discussionResponses} = await fetchStudentResponsesByDiscussionId(discussionId);
        console.log("Responses");
        //TODO need to simplify this and break it out
        console.log(discussionResponses);
        //update the courses by setting the state
        this.setState({discussionResponses});
    }

 

    render() { 
        const {discussions,discussionResponses,showHelpful} = this.state;
        return (  
            <div>
                <h1>Discussion Area </h1>
                 
                         {discussions.map((value,index)=>(
                        
                                <div key={index}>

                                 
                                     <div className="card">
                                                <h5 className="card-header">Discussion Topic : {value.discussionTitle}</h5>
                                                <div className="card-body">
                                                    <h5 className="card-text">{value.discussionQuestion}</h5>
                                                    <p className="card-text">{value.discussionDetail}</p>

                                                </div>
                                                <div>    <Link
                                                            to={`/createresponse/${value.discussionId}/discussionanswer`}//TODO send the student id as well
                                                            className="btn btn-primary">Reply</Link> </div>
                                                            <br/>
                                        </div>
                                                        
                                                        
                            </div>
                            ))}
               
                
             
                   
                <h3>The Student disusssion posts - Responses </h3>
                {discussionResponses.map((value,index)=>(
                        
                                <div key={index}>

                                <DiscussionCardResponse 
                                    name={"discussionReply"}
                                     discussResponseId={value["DiscussionResponses.discussResponseId"]}
                                     imageUrl={value.studentImageUrl}
                                     mediaUrl={value["DiscussionResponses.mediaUrl"]}
                                     authorName={value.firstName + ' ' + value.lastName}
                                     lastName={value.lastName}
                                     responseDetail={value["DiscussionResponses.responseDetail"]}
                                     timeStamp={value["DiscussionResponses.recordUpdatedTimeStamp"]}
                                     showHelpful={showHelpful}
                                     buttonLabel="Reply"
                                />

                 
                                                        
                            </div>
                            ))}
            </div>
        );
    }
}
 
export default DiscussionArea;