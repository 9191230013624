import React, { Component } from 'react'
import Form from '../common/form';
import Joi from 'joi-browser';
import {toast} from 'react-toastify';
import { postContactUs } from '../../services/contactus/contactUsService';

class ContactUs extends Form {
       state = {
        data:{
        
          
            emailAddress: '',
            isEmailVerified: false,
            fullName: '',
            phoneNumber:'',
            isPhoneNumberVerified: false,
            isHumanVerified: false,
            contactSubject: "",
            messageDetail: "",
            recordTimeStamp: "",
            isRead: false,
            didReply: false,
        },
        //courses: [],
        
        errors:{
           
        },
        baseState : {},
    };
    //Joi validation schema
    schema ={
      
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'edu','edu.jm'] } }).required().label('Email Address'),//use joi in node as well
        fullName: Joi.string().required().label('First and Last Name'),
        phoneNumber: Joi.string().regex(/^\d{3}-\d{3}-\d{4}$/).required().label('Phone Number'),
        contactSubject: Joi.string().required().label('Subject'),
        messageDetail: Joi.string().required().label('Message Detail'),
        recordTimeStamp: Joi.string().allow('').optional(),
        isEmailVerified: Joi.bool().optional(),
        isPhoneNumberVerified: Joi.bool().optional(),
        isHumanVerified: Joi.bool().optional(),
        isRead: Joi.bool().optional(),
        didReply: Joi.bool().optional(),
    }

    //set initial state to reset form on successful submit
    constructor(props){
        super(props)
    
        // preserve the initial state in a new object
        this.baseState = this.state ///>>>>>>>>> note this one.
    }
    resetForm = () => {
        this.setState(this.baseState) ///>>>>>>>>> note this one.
    }
 
doSubmit = async (e) =>{
        //handle the submission
          try{
             
            const {data} = this.state;
            
            //register and enroll the student
            await postContactUs(data);//todo need to test
      
           
            toast.success("Message Successfully Sent!");
            //reset the form and initial state
            this.resetForm();

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors = { ...this.state.errors};
                errors.emailAddress = ex.response.data;

                console.log(ex.response.data);
                toast.error(ex.response.data);
                //update UI
                this.setState({errors});
            }
        }
    }

    render() { 
        return (  
        <div className="contact-form-wrap pt-5">
            <div className="row">
                <div className="col-lg-5">
                    <div className="section-heading">
                        <p className="section__meta">Contact Us</p>
                        <h2 className="section__title">Have a Question? Drop Us a Line</h2>
                        <span className="section-divider"></span>
                        <p className="section__desc">
                            If you have questions, want to consult us on prospective projects. Feel free to send us a message and we will get back to you as soon as possible.
                        </p>
                        <ul className="social-profile">
                            <li><a to="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a to="#"><i className="fa fa-youtube-play"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="contact-form-action">
                        <form onSubmit={this.handleSubmit} >
                            <div className="row">
                                <div className="col-lg-6">
                                
                                     {this.renderTextInputLabel("fullName","Your Name","text","form-control","Your Name","label-text",true,"la la-user input-icon")}
                                </div>
                                <div className="col-lg-6">
                                      {this.renderTextInputLabel("emailAddress","Email Address","email","form-control","Email Address","label-text",true,"la la-envelope input-icon")}
                                </div>
                                <div className="col-lg-6">
                                       {this.renderTextInputLabel("phoneNumber","Phone Number","text","form-control","Phone Number","label-text",true,"la la-phone input-icon")}
                                </div>
                                <div className="col-lg-6">
                                       {this.renderTextInputLabel("contactSubject","Subject","text","form-control","Subject","label-text",true,"la la-book input-icon")}
                                </div>
                                <div className="col-lg-12">
            
                                     {this.renderMultiLineInput("messageDetail","Message","text","message-control form-control","Write your message","label-text","5",true,"la la-pencil input-icon")}
                                </div>
                                <div className="col-lg-12">
                                    
                                    {this.renderButton('Send Message',"theme-btn")} 
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
 
export default ContactUs;


