//fetch all the classes i registered for 
import React, { Component } from 'react';


class Dashboard extends Component {
    state = {  
         //the dashboard will show the current discussions etc
    }
    render() { 
        return (  
            <div>
                <h1>Student Dashboard</h1>
            </div>
        );
    }
}
 
export default Dashboard;