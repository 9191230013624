import React from 'react';
import {Link} from 'react-router-dom';


const CourseCard  = ({authorName,description, title, mediaUrl,path,...rest}) => {
    return (   
            
              <div class="col-lg-4"  {...rest}>
                <div class="card-item card-preview" data-tooltip-content="#tooltip_content_1">
                    <div class="card-image">
                        <Link to={path} class="card__img"><img src={mediaUrl} alt=""/></Link>
                        <div class="card-badge">
                            <span class="badge-label">bestseller</span>
                        </div>
                    </div>
                    <div class="card-content">
                        <p class="card__label">
                            <span class="card__label-text">beginner</span>
                            <Link to="#" class="card__collection-icon" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><span class="la la-heart-o"></span></Link>
                        </p>
                        <h3 class="card__title">
                            <Link to={path}>{title}</Link>
                        </h3>
                        <p class="card__author">
                            <Link to={path}>{authorName}</Link>
                        </p>
                        <div class="rating-wrap d-flex mt-2 mb-3">
                         <p>
                             {description}
                         </p>
                        </div>
                        <div class="card-action">
                            <ul class="card-duration d-flex justify-content-between align-items-center">
                                <li>
                                <span class="meta__date">
                                    <i class="la la-play-circle"></i> 45 Classes
                                </span>
                                </li>
                                <li>
                                <span class="meta__date">
                                    <i class="la la-clock-o"></i> 3 hours 20 min
                                </span>
                                </li>
                            </ul>
                        </div>
                        <div class="card-price-wrap d-flex justify-content-between align-items-center">
                            <span class="card__price">$58.00</span>
                            <Link to="#" class="text-btn">Add to cart</Link>
                        </div>
                    </div>
                </div>
            </div>
           
            
        );
    }

 
export default CourseCard;




