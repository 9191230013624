import React, { Component } from 'react';
import Joi from 'joi-browser';
import MultiLineInput from '../../common/multilineinput';
import http from '../../../services/httpService';
import {toast} from 'react-toastify';
//connection string
import config from '../../../constants/config.json';
import { fetchTargetDiscussion, fetchDiscussionResponsesByDiscussionResponseId } from '../../../services/courses/discussion/discussionService';
import DiscussionCard from '../../common/cards/discussioncard';

class CreateNewDiscussionResponseForm extends Component {
    //discussResponseId: {
    //need:  discussion ID, student ID, response detail
        state = {
        discussionResponses:{
            responseDetail: '',
        },
        discussion:[{
            discussionId: '',
            discussionTitle: '',
            discussionQuestion: '',
            mediaUrl: '',
            discussionDetail: ''
        }],
        discussionResponse:[{}],
        discussionId: '',
        discussResponseId: '',
        mode: '',
        errors:{
           
        }
    };
    //Joi validation schema
    schema ={
        responseDetail: Joi.string().required().label('Response Detail')
    }
    //go back to the previous page --start
     constructor(props)
     {
        super(props);
        this.goBack = this.goBack.bind(this); 

     }
 
    goBack = () =>{
        this.props.history.goBack()
    }

    //go back to the previous page --end

    async componentDidMount(){
      //  this.responseDetail.current.focus();
      this.state.mode = this.props.match.params.mode;
      console.log(this.state.mode);
      
       if(this.state.mode === "discussionanswer")
        {
            this.state.discussionId = this.props.match.params.id;
            console.log(this.state.discussionId);
            //TODO: Fetch the target discussion detail
            const {data: discussion} = await fetchTargetDiscussion(this.state.discussionId);
            console.log("Discussion Preview");
            console.log(discussion);
            this.setState({discussion});
            //show preview of the discussion the student is replying to 
            //API: create_discussion_response

        }
        else if(this.state.mode === "reply")
        {
            this.state.discussResponseId = this.props.match.params.id;
            console.log(this.state.discussResponseId);
             //show preview of the discussion response the student is replying to 
             //API: create_discussion_response_reply
            const {data : discussionResponse} = await fetchDiscussionResponsesByDiscussionResponseId(this.state.discussResponseId);
            console.log("Discussion Response Preview");
            this.setState({discussionResponse});

        }
        

     
        
    }

    validateProperty = ({name,value}) =>{
        const obj = {[name]: value};
        const schema = {[name]: this.schema[name]};
        const {error}  = Joi.validate(obj,schema );

        return error ? error.details[0].message : null; 
    };

    handleChange = ({currentTarget: input}) =>{
        //input validation
        const errors = {...this.state.errors};

        const errorMessage = this.validateProperty(input);

        if(errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        //discussionResponses seccount (model)
        const discussionResponses = {...this.state.discussionResponses};

        discussionResponses[input.name] = input.value;
        this.setState({discussionResponses, errors});
    }

    validate = () =>{

        const options = {abortEarly: false};
        const {error} = Joi.validate(this.state.discussionResponses,this.schema, options);
        if(!error) return null;

        const errors = {};

        for (let item of error.details)
            errors[item.path[0]] = item.message;

            return errors;
    };

     handleSubmit = async e =>{
        e.preventDefault();
        //get the values from the form
       // var responseDetail = this.responseDetail.current.value;
       // var password = document.getElementById('txtPassword').value;
        //call the server to save the changes
        //console.log(responseDetail + ' ' + password);
        const errors = this.validate();
        
        this.setState({errors : errors || {} });
        if(errors) return;

        //Submit the detail to the server
    
            if(this.state.mode === "discussionanswer")
            {
                //THen it should write a new post to answer the discussion question
                const obj = {
                    responseDetail: this.state.discussionResponses.responseDetail,
                    studentId: '08bc087b-7232-4b33-ae46-f00b8c494375',//need to get this from the logged in user
                    discussionId: this.state.discussionId,//get this based on the id from the selected discussion
                    numOfReplies: 0,
                    recordTimeStamp: Date().toLocaleString(),//student.recordTimeStamp,
                    recordUpdatedTimeStamp: Date().toLocaleString(),
                };
                //send to the server
                const {data: discussionResponses} = await http.post(config.apiEndpoint + '/studentdiscussionresponse/api/create_discussion_response',obj);
                //log the response
                console.log(discussionResponses);
                toast.success("New Response Successfully Added!");
                //update the UI
                this.setState({discussionResponses});
                //go back to the discussions page
                this.props.history.goBack();
            }
            else if(this.state.mode === "reply"){
                //Then reply response to another student answering the question
                //THen it should write a new post to answer the discussion question
                const obj = {
                    responseDetail: this.state.discussionResponses.responseDetail,
                    studentId: '08bc087b-7232-4b33-ae46-f00b8c494375',//need to get this from the logged in user
                    discussResponseId: this.state.discussResponseId,//in this case it is the response id
                    numOfReplies: 0,
                    recordTimeStamp: Date().toLocaleString(),//student.recordTimeStamp,
                    recordUpdatedTimeStamp: Date().toLocaleString(),
                    //mediaUrl in the future
                    //student info is in the future as well
                    
                };
                //send to the server
                const {data: discussionResponses} = await http.post(config.apiEndpoint + '/studentdiscussionresponse/api/create_discussion_response_reply',obj);
                //log the response
                console.log(discussionResponses);
                toast.success("New Response Successfully Added!");
                //update the UI
                this.setState({discussionResponses});
                //go back to the discussions page
                this.props.history.goBack();
            }
        };
    
       
    
    render() { 

        const {discussionResponses,errors,discussion,discussionResponse} = this.state;

        return (  
            <div>
      
                
                      {this.state.discussionId && discussion.map((value,index)=>(
                        
                                <div key={index}>


                                        <DiscussionCard title={value.discussionTitle} 
                                        firstName={discussionResponse["firstName"]}
                                        lastName={discussionResponse["lastName"]}
                                        question ={value.discussionQuestion}
                                        questionDetail={value.discussionDetail}
                                        label={"preview"}

                                        />
                                                        
                                                        
                            </div>
                            ))}
                              {this.state.discussResponseId &&

                               <DiscussionCard 
                                        title="Post Reply"
                                       /* question ={value.discussionQuestion}*/
                                        firstName={discussionResponse["firstName"]}
                                        lastName={discussionResponse["lastName"]}
                                        questionDetail={discussionResponse["DiscussionResponses.responseDetail"]}
                                        label={"preview"}

                                        />
                            }
              
                <form onSubmit={this.handleSubmit}> 
                <div className="col-lg-12">
            
             
                    <MultiLineInput name="responseDetail"
                           value={discussionResponses.responseDetail}
                           label="Discussion Reply"
                          labelClass="label-text"
                          className="message-control form-control"
                          type="text" 
                          numOfRows="5"
                          isRequired="true"
                          placeholder="Write your response"                    
                          error={errors.responseDetail}
                          onChange={this.handleChange}
                          //iconClassName="la la-pencil input-icon"
                   />

               
                 </div>
                 <div className="col-lg-12 ">
                    <div className="btn-box">
                        <button className=" theme-btn btn-danger" onClick={() => this.props.history.goBack()} >Cancel</button>
                        &nbsp;
                        <button
                        disabled={this.validate()}
                        className="theme-btn">Post</button>

                      </div>
                    </div>
                  </form>
            </div>
        );
    }
}
 
export default CreateNewDiscussionResponseForm;