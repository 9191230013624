import React, { Component } from 'react'
import Iframe from 'react-iframe';
//import Form from '../../common/form';
import Joi from 'joi-browser';
import {toast} from 'react-toastify';
import CheckerQuestionShortAns from './checkquestiontypes/checkerquestionshortans';
import { alternatives } from 'joi';
//ReactRadioButtonGroup
import ReactRadioButtonGroup from 'react-radio-button-group';

class ActiveCheckerQuestion extends Component {
    //useful link: https://stackoverflow.com/questions/59861503/joi-validator-conditional-schema
    //Do the short answer first then the multiple choice variant after
    state = {  
        questionInfo:{
            questionId: '1314',
            questionTitle: 'Test',
            questionDetail: '',
            questionVideoUrl:'https://www.youtube.com/embed/fnkFOhA7FC4',
            questionImageUrl:'',
          //  questionImageUrl: 'https://res.cloudinary.com/picked/image/upload/f_auto/v1601661243/cms/aptitude-test-sample-questions-and-answers-1601661243',
           // isMultipleChoice: true,
            isShortAnswer: true,
            questionShortAnswerDetail: '',
        },
        data:{
            questionShortAnswerDetail: "",
            isMultipleChoice: true,//set this to determine if I should have multiple choice or not
            selectedAnswer:'',
            answersOptions: [
              "Egypt",
              "London",
              "Amsterdam",
              "NewYork"
            ],
            correct: 1
        },
        alphabet:[
            "a",
            "b",
            "c",
            "d"
        ],
        errors:{
           
        },
    }
 //Joi validation schema
    schema ={
      
      /*  questionTitle: Joi.string().optional().label('Question Title'),
        questionDetail: Joi.string().optional().label('Question Detail'),
        questionVideoUrl: Joi.string().optional().label('Question Helper Video'),
        questionImageUrl: Joi.string().optional().label('Question Title'),*/
        answersOptions: Joi.array().optional(),
        correct: Joi.number().optional(),
        isMultipleChoice: Joi.bool().required(),
        selectedAnswer: Joi.string().label('Checker Question Short Answer').when('isMultipleChoice', { is: false, then: Joi.string().required().allow(null, '') }),
        //questionShortAnswerDetail: Joi.string().optional().label('Checker Question Short Answer'),
        questionShortAnswerDetail: Joi.string().label('Checker Question Short Answer').when('isMultipleChoice', { is: true, then: Joi.string().optional().allow(null, '') }),
                                 //   .label('Checker Question Short Answer'),
        
       
    }


      validate = () =>{

        const options = {abortEarly: false};
        const {error} = Joi.validate(this.state.data,this.schema, options);
        if(!error) return null;

        const errors = {};

        for (let item of error.details)
            errors[item.path[0]] = item.message;
            console.log(errors);
            return errors;
    };
      validateProperty = ({name,value}) =>{
        const obj = {[name]: value};
        const schema = {[name]: this.schema[name]};
        const {error}  = Joi.validate(obj,schema );

        return error ? error.details[0].message : null; 
    };

   handleSubmit = e =>{
        e.preventDefault();
        //get the values from the form
       // var emailAddress = this.emailAddress.current.value;
       // var password = document.getElementById('txtPassword').value;
        //call the server to save the changes
        
        const errors = this.validate();
        console.log(errors);
        this.setState({errors : errors || {} });
        if(errors) return;

        this.doSubmit();
        
    }

    doSubmit = async (e) =>{
        //handle the submission
          try{
             
            const {data} = this.state;
            console.log("Student Answer");
            console.log(data);
            //question info
            const questionDetails = this.state.questionInfo;
            console.log("Question Information");
            console.log(questionDetails);
            //the multiple choice answer given
            console.log("Multi-Choice Selected Answer from the radio button group");
            console.log(data.selectedAnswer);
            console.log("Short Answer");
            console.log(data.questionShortAnswerDetail);

            //TODO get the student information that will be submited with this to identitfy the question being answered


            //submit checker question detail
           // await postContactUs(data);//todo need to test
      
           
            toast.success("Message Successfully Sent!");
            //reset the form and initial state
            //uncomment when finished
            //this.resetForm();

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors = { ...this.state.errors};
                errors.emailAddress = ex.response.data;

                console.log(ex.response.data);
                toast.error(ex.response.data);
                //update UI
                this.setState({errors});
            }
        }
    }

     constructor(props){
        super(props)
    
        // preserve the initial state in a new object
        this.baseState = this.state ///>>>>>>>>> note this one.

        this.handleOptionChange = this.handleOptionChange.bind(this);
    }
    resetForm = () => {
        this.setState(this.baseState) ///>>>>>>>>> note this one.
    }

    handleChange = ({currentTarget: input}) =>{
        //input validation
        const errors = {...this.state.errors};

        const errorMessage = this.validateProperty(input);

        if(errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        //data seccount (model)
        const data = {...this.state.data};

        data[input.name] = input.value;
        this.setState({data, errors});
    }
    handleOptionChange = ({currentTarget: input}) =>{
        console.log("Called");
      //  this.setState({
           // data.selectedAnswer = changeEvent.target.value
        //});
      //  this.selectedAnswer = e.currentTarget.value;
       //this.data.selectedAnswer = e.currentTarget.value
         const data = {...this.state.data};
        console.log(input.name);
        //set the selected value from the radio buttons 
        data["selectedAnswer"] = input.value;
        this.setState({data});
       
    }
    
    render() { 
       const {data,questionInfo,alphabet,errors} = this.state;
        return ( 
          
              <div>
            {data.isMultipleChoice &&
                <section>
                          {
                          //TODO make this into a reusable when finished and planning the DB tables as well as the methods to capture
                          //this, set the student as present etc either Python or NodeJS
                          }
                        <h5>Checker Question </h5>
                        {questionInfo.questionVideoUrl &&
                            <Iframe url={questionInfo.questionVideoUrl}
                                    width="100%"
                                    height="465px"
                                    id="tutorialVideo"
                                    className="card-image"
                                    display="initial"
                                    frameborder="0" 
                                    allowfullscreen
                                    position="relative"/>

                        }
                        {questionInfo.questionImageUrl &&
                            <img src={ questionInfo.questionImageUrl} alt="checker question image"
                             width="100%"
                                    height="465px"/>
                        }
                         <h4>{questionInfo.questionTitle}</h4>
                
                 <h5> Choice Selected: {data.selectedAnswer}</h5>
               
                      <ul>
                            {data.answersOptions.map((value,index)=>(
                        
                                <li key={index}>
                                    <div style={{paddingBottom:"1%"}} className="btn-group-toggle" data-toggle="buttons">
                                        
                                    <button style={{width: "100%",height:"50px"}} id="clickedButtonChange" 
                                    className="btn  btn-outline-secondary" onClick={this.handleOptionChange} value={value}>   <b>{value}</b></button>     
                                    
                                    </div>
                                </li>
                                                
                            ))}
                        </ul>
                    </section>

            }   

                <form onSubmit={this.handleSubmit} >
             
    
                        {!data.isMultipleChoice &&
                            
                         
                            <CheckerQuestionShortAns 
                            questionTitle={questionInfo.questionTitle}
                            questionVideoUrl={questionInfo.questionVideoUrl}
                            questionImageUrl={questionInfo.questionImageUrl}
                            name={"questionShortAnswerDetail"}
                            label={"Checker Question Short Answer"}
                            className={"message-control form-control"}
                            placeholder={"Write your short answer here."}
                            labelClass={"label-text"}
                            iconClassName={""}
                            value={data.questionShortAnswerDetail}
                            onChange={this.handleChange}
                          
                            type={"text"}
                            error={errors.questionShortAnswerDetail}

                            />
                    }
                      

                                <button
                      disabled={this.validate()}
                      className="btn btn-success btn-block">Submit Question</button>
                </form>

                </div>
        
         );
    }
}
 
export default ActiveCheckerQuestion;