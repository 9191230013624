import React, { Component } from 'react';
import http from '../../../services/httpService';
//connection string
import config from '../../../constants/config.json';
import CardList from '../../common/cardlist';
import SearchBox from '../../common/searchbox';
import DiscussionArea from './discussionarea';
import CourseListCard from '../../common/cards/courselistcard';

class CourseDiscussions extends Component {

    //TODO: add search to search for discussion, then add on click to go from selected discussion to the target disussion and all
    //the replies for that discussion
    //TODO: add login and hide pages for those who arent logged in

    state = {  
        discussions: [{
            discussionId: '',
            discussionTitle: '',
            discussionQuestion: '',
            discussionDetail: '',
            mediaUrl: '',
            dueDateTimeStampStr: ''
        }],
        currentPage: 1,
        pageSize: 4,
        searchQuery: "",
        selectedDiscussionStatus: null,
        sortColumn: {path: "title",order: "asc"}

    }
    //TODO discussionarea/:id to get to the discussion area
//show all the discussions then click the discussion to show all the sub discussions
    async componentDidMount() {
        const moduleId = this.props.match.params.moduleId;
        //promise will complete in the future or in pending then resolved (success) or rejected if fail
        //wait till the promised results appears then extract the values
        //get data and rename it to courses (the object name)
        //get all discussion by the module id
        //TODO ned a method to show the courses enrolled in by this student -> the modules -> discussions in module to set this
        const {data: discussions} = await http.get(config.apiEndpoint +'/courses/api/fetch_all_course_curriculum_mod_discussions/'+moduleId);
        //const result = Object.entries(discussions);
        console.log( discussions);
        //update the courses by setting the state
        this.setState({discussions});

    }

    //finish implementing this
    /*

    handlePageChange = page =>{
        this.setState({currentPage: page});
    };
    //selectedDiscussionStatus can be closed or open or upcoming
    handleDiscussionStatusSelect = discussionStatus =>{
        this.setState({selectedDiscussionStatus: discussionStatus, searchQuery: "", currentPage: 1});
    };
    handleSearch = query =>{
        //update the UI as the state changes
        this.setState({searchQuery: query, selectedDiscussionStatus: null, currentPage: 1});
    };
getPagedData = () =>{
        const{
            pageSize,
            currentPage,
            sortColumn,
            selectedDiscussionStatus,
            searchQuery,
            discussions: discussions
        } = this.state;

        let filtered = discussions;

        if(searchQuery)
            filtered = discussions.filter(m =>
                m.discussionTitle.toLowerCase().startsWith(searchQuery.toLowerCase()));
        else if(selectedDiscussionStatus && selectedDiscussionStatus.discussionId)
                filtered = discussions.filter(m => m.discussion.discussionId === selectedDiscussionStatus.discussionId);

        const sorted = _.orderBy(filtered, [sortColumn.path],[sortColumn.order]);
        const courseDiscussions = paginate(sorted,currentPage,pageSize);

        return {totalCount: filtered.length, data: courseDiscussions};
    }*/


    render() { 
        
         const {discussions} = this.state;
        return (  
            <div>
                <h1>THis is the list where all the discussions take page </h1>
                <h2>First the Discussion Details</h2>
                <h3>The Student disusssion posts</h3>
                {/*<SearchBox value={searchQuery} onChange={this.handleSearch}/>*/}
                <ul>
                         {discussions.map((value,index)=>(
                        
                                <li key={index}>

                                 
                                  <CourseListCard name="discussion"
                                    discussionId ={value.discussionId}
                                    question = {value.question}
                                    discussionDetail={value.discussionDetail}
                                    title={value.discussionTitle}
                                    mediaUrl ={ value.mediaUrl}
                                    timeStamp={value.dueDateTimeStampStr}
                                 />
                                
                       </li>
                            ))}
                </ul>
                
                
                
            </div>
        );
    }
}
 
export default CourseDiscussions;