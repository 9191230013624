import React from 'react'
import { Link } from 'react-router-dom';

const DiscussionCard = ({title,question,questionDetail, label,mediaUrl,firstName,lastName,discussResponseId, ...rest}) => {
    let defaultImage = "https://firebasestorage.googleapis.com/v0/b/vengarreweb-b5979.appspot.com/o/vengarreUserAuthorImages%2FAuthor_Image_155346228884902.png?alt=media&token=4caba676-7e1c-4ceb-b447-7500991d2e88";

    return (  

        <div className="card">
            <h5 className="card-header">Discussion Topic : {title} <small>{label}</small></h5>
            <div className="card-body" {...rest}>
                { firstName && lastName &&
                    <h5 className="card-text">{firstName} {lastName}</h5>
                }
                <h5 className="card-text">{question}</h5>
                <p className="card-text">{questionDetail}</p>
                {mediaUrl && <div style={{"float":"left"}}>
                    <p>Supporting Attachment </p>
                    <img src={mediaUrl != null?mediaUrl: defaultImage } alt="..." className="card-img" style={{height:"200px","objectFit": "contain"}} />
                    </div>}
            </div>
            {discussResponseId &&
             <Link
                style={{height: "40px",width:"100px"}}               
                to={`/createresponse/${discussResponseId}/reply`}//TODO send the student id as well
                className="btn btn-primary">Reply </Link>
        
            }        
            <br/>   
        </div>
                                                  
    );
}
 
export default DiscussionCard;