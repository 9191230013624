import http from '../httpService';
import {apiEndpoint} from '../../constants/config.json';

export function registerNewsLetterSubscriber(emailAddress){
    const fullApiEndpoint = apiEndpoint + "/newletter/api/create_new_newsletter_subscriber";
    //need to write to firebase then the server
    return http.post(fullApiEndpoint,{
        subListEmailAddress: emailAddress,
        createdDatetime: Date(),
        isJunk: false
    });
}
