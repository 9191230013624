import http from '../httpService';
import {apiEndpoint} from '../../constants/config.json';


export function fetchAllCourses(){
    const fullApiEndpoint = apiEndpoint + "/courses/api/get_basic_course_headers";
    return http.get(fullApiEndpoint);
}

export function fetchAllCoursesStudentEnrolledInById(studentId){
    const fullApiEndpoint = apiEndpoint + "/studentcourseenrollment/api/get_all_course_enrol_by_student_id/"+studentId;
    return http.get(fullApiEndpoint);
}
///api/get_all_modules_by_course_id/
export function fetchAllModulesByCourseId(courseId){
    const fullApiEndpoint = apiEndpoint + "/courses/api/get_all_modules_by_course_id/"+courseId;
    return http.get(fullApiEndpoint);
}