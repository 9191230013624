import React, { Component } from 'react'
import { fetchAllModulesByCourseId } from '../../../../services/courses/coursesService';
import MyCourseCard from '../../../common/cards/mycoursecard';

class CourseModules extends Component {
    state = { 
        modules:[{

        }],
        curriculumName: '',
     }
     async componentDidMount(){
         const {data: results} = await fetchAllModulesByCourseId(this.props.match.params.courseId);
         console.log("Modules");
         console.log(results);
         if(results[0] != null){
           
          
          
            if(results[0].curriculumName != null)
            {
                console.log("Called")
                const curriculumName = results[0].curriculumName;
                this.setState({curriculumName});
            }
            if(results[0].CurriculumModules != null)
            {
                const modules = results[0].CurriculumModules;
                console.log(modules);
                this.setState({modules});
            }

         }
     }
    render() { 
       // const {curriculumName,modules} = this.state;
        return ( 
            <div>
                {this.state.curriculumName && 
                    <h3>Module: {this.state.curriculumName}</h3>

                }
                {!this.state.curriculumName &&
                    <React.Fragment>
                        <br/>
                        <h4>Seems no Modules presently exist :( come back later.</h4>
                    </React.Fragment>
                }
               
                {(this.state.curriculumName && this.state.modules.length > 0) && this.state.modules.map((value,index)=>(
                        //hide the Quick Links here show the class stats
                       
                                <div key={index}>

                                 
                                  <MyCourseCard name="modules"
                                    courseId ={value.moduleId}
                                    authorName={value.firstName + " " + value.lastName}
                                    question = {value.moduleName}
                                    discussionDetail={value["CourseOverviews.courseDescription"]}
                                    title={value.moduleName}
                                    //mediaUrl ={ value["CourseOverviews.coursePromoImageUrl"]}
                                    timeStamp={value.dueDateTimeStampStr}
                                    courseCost={value["CourseOverviews.coursePrice"]}
                                    hasQuickLinks={true}
                                    showPrice={false}
                                    showAuthor={false}
                                    destination={'coursemodulearea'}
                                 />
                                
                       </div>
                ))}
            </div>
         );
    }
}
 
export default CourseModules;