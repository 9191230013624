import React from 'react';

const CoursesSelect = ({name, label, options, error, ...rest}) =>{
    return(
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select name={name} id={name} {...rest} className="form-control">
                <option value=""/>
                {
                    options.map(option =>(
                        <option key={option.courseId} value={option.courseId}>
                            {option.courseTitle}
                        </option>
                    ))
                }
            </select>
            
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    )
};

export default CoursesSelect;