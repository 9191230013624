import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import DiscussionCard from '../../../common/cards/discussioncard';
import { fetchDiscussionResponsesByDiscussionResponseId,fetchDiscussionResponseRepliesByDiscussionResponseId } from '../../../../services/courses/discussion/discussionService';
import DiscussionCardResponse from '../../../common/discussioncards/discussioncardresponse';

class DiscussionResponse extends Component {
    state = { 
        discussResponseId: '',
        discussionResponse: [],
        discussionResponseReplies: [],
        showHelpful: false,
     }

     constructor(props)
     {
        super(props);
        this.goBack = this.goBack.bind(this); 

     }

    async componentDidMount() {
        this.state.discussResponseId = this.props.match.params.discussResponseId;
       
        console.log("Response ID: " +this.state.discussResponseId);
        //fetch the discussion response detail 
        const {data : discussionResponse} = await fetchDiscussionResponsesByDiscussionResponseId(this.state.discussResponseId);
        console.log("Discussion Response Preview");
        console.log(discussionResponse);
        this.setState({discussionResponse});
        
        //and fetch all the discussion response replies
        const {data: discussionResponseReplies} = await fetchDiscussionResponseRepliesByDiscussionResponseId(this.state.discussResponseId);
        console.log("Discussion Response  Reply Preview");
        console.log(discussionResponseReplies);
        this.setState({discussionResponseReplies});

    }
    goBack = () =>{
        this.props.history.goBack()
    }

    render() { 
        const {discussResponseId,discussionResponse,discussionResponseReplies,showHelpful} = this.state;
        return ( 
            <div>
              
                <Link onClick={() => this.props.history.goBack()} className="btn btn-info"><i className="la la-angle-left"></i> Back</Link>
              
                   {this.state.discussResponseId &&

                               <DiscussionCard 
                                        title="Post Reply"
                                       /* question ={value.discussionQuestion}*/
                                        firstName={discussionResponse["firstName"]}
                                        lastName={discussionResponse["lastName"]}
                                        questionDetail={discussionResponse["DiscussionResponses.responseDetail"]}
                                        label={"preview"}
                                        mediaUrl={discussionResponse["DiscussionResponses.mediaUrl"]}
                                        discussResponseId={this.props.match.params.discussResponseId}
                                        />

                                        
                            }
                          
                        <h3><u>Replies</u></h3>
                      {
                          discussionResponseReplies.length < 1 &&
                          <h2>Its Empty here, try responding to a fellow student.</h2>
                      }
                           
                                {discussionResponseReplies &&

                                discussionResponseReplies.map((value,index)=>(
                        
                                <div key={index}>

                                  <DiscussionCardResponse 
                                    name={"discussionReply"}
                                     //discussResponseId={value["DiscussionResponseReplies.discussResponseId"]}
                                     imageUrl={value.studentImageUrl}
                                     mediaUrl={value["DiscussionResponseReplies.mediaUrl"]}
                                     authorName={value.firstName + ' ' + value.lastName}
                                     lastName={value.lastName}
                                     responseDetail={value["DiscussionResponseReplies.responseDetail"]}
                                     timeStamp={value["DiscussionResponseReplies.recordUpdatedTimeStamp"]}
                                     showHelpful={showHelpful}
                                />


                 
                                                        
                            </div>
                              ))}
                                
                                

            </div>
         );
    }
}
 
export default DiscussionResponse;