import React from 'react';
import MultiLineInput from '../../../common/multilineinput';
import Iframe from 'react-iframe';

const CheckerQuestionShortAns = ({questionTitle,questionVideoUrl,questionImageUrl,name, label,className,placeholder,labelClass,iconClassName, value,onChange,type,error}) => {
    return (  

           <section >
                        <h5>Checker Question </h5>
                        {questionVideoUrl &&
                            <Iframe url={questionVideoUrl}
                                    width="100%"
                                    height="465px"
                                    id="tutorialVideo"
                                    className="card-image"
                                    display="initial"
                                    frameborder="0" 
                                    allowfullscreen
                                    position="relative"/>

                        }
                        {questionImageUrl &&
                            <img src={ questionImageUrl} alt="checker question image"
                             width="100%"
                                    height="465px"/>
                        }
                         <h4>{questionTitle}</h4>
                
   
             
                       
                            {/*this.renderMultiLineInput("questionShortAnswerDetail","Checker Short Answer","text","message-control form-control","Write your short answer here.","label-text","5",true)*/}
             <MultiLineInput name={name}
                          value={value}
                          label={label}
                          labelClass={labelClass}
                          className={className}
                         // type={type}   
                          numOfRows={5}
                          isRequired={true}
                          placeholder={placeholder}                     
                          error={error}
                          onChange={onChange}
                         // iconClassName={iconClassName}
                   />
                     
                </section>


    );
}
 
export default CheckerQuestionShortAns;

