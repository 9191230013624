import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Moment from 'react-moment';

const DiscussionCardResponse  = ({authorName, timeStamp,responseDetail,mediaUrl,discussResponseId,showHelpful,buttonLabel}) => {
    let defaultImage = "https://firebasestorage.googleapis.com/v0/b/vengarreweb-b5979.appspot.com/o/vengarreUserAuthorImages%2FAuthor_Image_155346228884902.png?alt=media&token=4caba676-7e1c-4ceb-b447-7500991d2e88";

    return (   
        <div className="comment">
                    <div className="comment-avatar">
                        <img className="avatar__img" alt="" src={mediaUrl!= null?mediaUrl: defaultImage }/>
                    </div>
                    <div className="comment-body">
                        <div className="meta-data">
                            <h3 className="comment__author">{authorName}</h3>
                            <p className="comment__date"><Moment fromNow>{timeStamp != null?
                            timeStamp : Date().toLocaleString()}</Moment></p>
                        </div>
                        <p className="comment-content">
                            {responseDetail}
                        </p>
                        <div className="comment-reply">

                             {discussResponseId &&
                                <Link
                    
                                to={`/discussionresponse/${discussResponseId}/reply`}//TODO send the student id as well
                                className="theme-btn"><span className="la la-mail-reply"></span> {buttonLabel}</Link>
                             }
                            {showHelpful &&
                                <div className="helpful__action d-flex align-items-center">
                                    <span className="helpful__action-text">Was this review helpful?</span>
                                    <button className="btn">Yes</button>
                                    <button className="btn">No</button>
                                    <div className="msg-action-dot">
                                        <div className="dropdown">
                                            <a className="action-dot" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="la la-ellipsis-v"></i>
                                            </a>
                                            <div className="dropdown-menu border">
                                                <ul>
                                                    <li><a className="dropdown-item" to="javascript:void(0)" data-toggle="modal" data-target=".report-modal-form"><i className="la la-flag mr-2"></i>Report abuse</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
        </div>
           
        );
    }

 
export default DiscussionCardResponse;