import http from '../../httpService';
import {apiEndpoint} from '../../../constants/config.json';


export function fetchTargetDiscussion(discussionId){
    const fullApiEndpoint = apiEndpoint + "/courses/api/fetch_discussion_by_id/" + discussionId;
    return http.get(fullApiEndpoint);
}

//get all discussion responses by discussion id
// /api/get_student_profiles_resp_by_discussion_id/:discussionId

export function fetchStudentResponsesByDiscussionId(discussionId){
    const fullApiEndpoint = apiEndpoint + "/studentdiscussionresponse/api/get_student_profiles_resp_by_discussion_id/" + discussionId;
    return http.get(fullApiEndpoint);
}

//http.post(config.apiEndpoint + '/studentdiscussionresponse/api/create_discussion_response',obj);

export function createDiscussionResponse(obj){
   // const fullApiEndpoint = apiEndpoint + "/studentdiscussionresponse/api/get_student_profiles_resp_by_discussion_id/" + discussionId;
    return http.post(apiEndpoint + '/studentdiscussionresponse/api/create_discussion_response',obj);
}

//get_discussion_response_by_id parameter discussResponseId
export function fetchDiscussionResponsesByDiscussionResponseId(discussResponseId){
    const fullApiEndpoint = apiEndpoint + "/studentdiscussionresponse/api/get_discussion_response_by_id/" + discussResponseId;
    return http.get(fullApiEndpoint);
}

//get_discussion_response_reply_by_id parameter discussResponseId
export function fetchDiscussionResponseRepliesByDiscussionResponseId(discussResponseId){
    const fullApiEndpoint = apiEndpoint + "/studentdiscussionresponse/api/get_discussion_response_reply_by_id/" + discussResponseId;
    return http.get(fullApiEndpoint);
}