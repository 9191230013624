import { createContext } from "react";
import Input from '../common/input';
import Form from '../common/form';
import Joi from 'joi-browser';
import React, { Component } from 'react';
import { registerNewStudent,loginWithJwt } from '../../services/students/studentauthen';
import { fetchAllCourses } from '../../services/courses/coursesService';
import {toast} from 'react-toastify';

//TODO log all failed attempts
class RegisterStudent extends Form {

    state = {
        data:{
        
            emailAddress: '',
            password: '',
            firstName: '',
            lastName: '',
            studentIdNumber:'',
            studentAccessCode: '',//the course code will come later
            isCourseFree: true,//need to set this later
            isPaid: false,//need to se this later before premium offerings
            courseId: "",//to link the user to the course they are enrolling for
            

        },
        courses: [{

          courseCurriculums:[],
        }],
        errors:{
           
        }
    };
    //Joi validation schema
    schema ={
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'edu','edu.jm'] } }).required().label('Email Address'),//use joi in node as well
        password: Joi.string().required().label('Password'),
        firstName: Joi.string().required().label('First Name'),
        lastName: Joi.string().required().label('Last Name'),
        studentIdNumber: Joi.string().min(6).max(10).required().label('Student ID Number'),
        studentAccessCode: Joi.string().required().label('Student Access Code'),//need a hint what this is
        courseId: Joi.string().required().label('Course Code'),
        isCourseFree: Joi.bool().optional(),
        isPaid: Joi.bool().optional(),
    }
 

    async componentDidMount(){
      //set the focus
    
      //add the courses to a dropdown list
      //because its a promise we have to await the results
      const result = await fetchAllCourses();
        console.log(result.data);
      const courses = result.data;//[{courseId: "",courseTitle: "", ...result.data}];
       console.log(courses);
      //update the UI
      this.setState({courses});
    }

   

  
     doSubmit = async () =>{
        //handle the submission
          try{

            //get the curriculum for the target course
        // console.log("CourseId: " + this.state.data.courseId);
          const targetCourse = this.state.courses.filter(course => course.courseId === this.state.data.courseId);
          // console.log("Target Course");
        // console.log(targetCourse.length);
               //  console.log("Check");
         //console.log("Count: "+ targetCourse[0].CourseCurriculums.length);
         if(targetCourse.length > 0){
            // console.log("Curriculum count: "+ targetCourse[0].CourseCurriculums.length);
            if(targetCourse[0].CourseCurriculums.length > 0)
            {
              const courseCurriculumId = targetCourse[0].CourseCurriculums[0].courseCurriculumId;
                // console.log("CurriculumID found: "+ courseCurriculumId);
              //register and enroll the student
                const response = await registerNewStudent(this.state.data,courseCurriculumId);

               //console.log(response);
                loginWithJwt(response.headers['x-auth-token']);
                //if successful redirect to student dashboard where the courses registered for appears etc
                toast.success("Your Student data Successfully Created!");
                //go to student dashboard
                window.location = "/dashboard";

            }
            else{
              //console.log("No Course Curriculum found.");
              toast.error("No Course Curriculum found. Must have Curriculum to select it.");
              return;
            }
          }
           

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors = { ...this.state.errors};
                errors.emailAddress = ex.response.data;

                console.log(ex.response.data);
                toast.error(ex.response.data);
                //update UI
                this.setState({errors});
            }
        }
    }

    render() { 
        const {data,errors} = this.state;

        return (  
            <div className="card-box-shared-body">
            
              <div className="card-box-shared-title text-center">
                    <h3 className="widget-title font-size-35">Student - Sign Up</h3>
                </div>
               
                    
                  <form onSubmit={this.handleSubmit} className="contact-form-action"> 
                  
                 <div className="col-lg-12">
                    <div className="input-box">
                       {this.renderSelectCourses("courseId","Course Code",this.state.courses)}
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="input-box">
                      {this.renderTextInputLabel("studentAccessCode","Student Access Code","text","form-control","Student Access Code","label-text",true,"la la-barcode input-icon")}
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="input-box">
                        {this.renderTextInputLabel("firstName","First Name","text","form-control","First Name","label-text",true,"la la-user input-icon")}
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="input-box">
                     {this.renderTextInputLabel("lastName","Last Name","text","form-control","Last Name","label-text",true,"la la-user input-icon")}
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="input-box">
                      {this.renderTextInputLabel("studentIdNumber","Student ID Number","text","form-control","Student Id Number","label-text",true,"la la-user input-icon")}
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="input-box">
                      {this.renderTextInputLabel("emailAddress","Email Address","text","form-control","Email Address","label-text",true,"la la-envelope input-icon")}
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="input-box">
                        {this.renderTextInputLabel("password","Password","password","form-control","Password","label-text",true,"la la-key input-icon")}
                    </div>
                  </div>
                  <div className="col-lg-12 ">
                    <div className="btn-box">               
                        {this.renderButton('Create New Account',"btn btn-success theme-btn")}
                    </div>
                  </div>
                   
                  </form>

                
            </div>
        );
    }
}
 
export default RegisterStudent;