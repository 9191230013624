import React, { Component } from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { getTargetTutorialByTitle,getAuthorProfileById,fetchLatestThreeTutorials } from '../../services/tutorials/tutorialService';
import Moment from 'react-moment';
import { PrismCode } from '../common/sourcecode/prismcode';
import { registerNewsLetterSubscriber } from '../../services/newsletter/newsletterService';
import {toast} from 'react-toastify';

class Tutorial extends Form {
    //{this.props.match.params.title}
    state = {  
        data:{
            emailAddress: '',
     
        },
    tutorial: {
        technicalTutorialId:'',
        youTubeTutorialTitle: '',
        youTubeTutorialEmbeddedVideoUrl: '',
        youTubeTutorialDescription: '',
        introToTutorialSection: '',
        mainContent: '',
        mainPromotionalImageUrl: '',
        createdAt: '',
        TutorialStepCodeDetails:[{
            tutorialStepCodeDetailId:'',
            sourceCodeUrl:'',
            sourceCodeDetail:'',
            language: '',
            sourceCodeSnippetSectionTitle: '',
            sourceCodeSnippetExplanation:'',
            languageId:'',
        }],
              
            },
    author:{
        firstName: '',
        lastName:'',
        userProfileImageUrl:'',
        userBriefBio:''
    },
    latestTutorials:[{}],
     errors:{
           
        }

    }
 doSubmit = async () =>{
        //handle the submission
            const {data} = this.state;
       
            //register and enroll the student
            await registerNewsLetterSubscriber(data.emailAddress);
            //reset the value
            data.emailAddress = '';
            toast.success("Your Email added to the newsletter list, Thank You!");
         

    }
     //Joi validation schema
    schema ={
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'edu','edu.jm'] } }).required().label('Email Address'),//use joi in node as well
       
    }
 

     async componentDidMount() {
        //promise will complete in the future or in pending then resolved (success) or rejected if fail
        //wait till the promised results appears then extract the values
        //get data and rename it to courses (the object name)
        //get all discussion by the module id
        const {data: tutorial} = await getTargetTutorialByTitle(this.props.match.params.title);
        //const result = Object.entries(discussions);
       // console.log( tutorial);
        //update the courses by setting the state
        this.setState({tutorial});

        //highlight the code
        //Prism.highlightAll();
        //fetch the author info
        const {data: author} = await getAuthorProfileById(tutorial.userId);
        //console.log(author);

        this.setState({author});//must be called once

        //fetch the latest tutorials
        const {data: latestTutorials} = await fetchLatestThreeTutorials();
        console.log(latestTutorials);
        this.setState({latestTutorials});
    }


    render() { 
        const {tutorial,author,latestTutorials} = this.state;
        return ( 
            
        <section className="blog-details " style={{paddingTop:"4%"}}>
            <div className="container">
                <div className="blog-details-wrap">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card-item blog-card">
                                <div className="card-image">
                                    <Link to="#" className="card__img">
                                     
                                      {tutorial.youTubeTutorialEmbeddedVideoUrl &&
                                      

                                        <Iframe url={tutorial.youTubeTutorialEmbeddedVideoUrl }
                                                width="100%"
                                                height="465px"
                                                id="tutorialVideo"
                                                className="card-image"
                                                display="initial"
                                                frameborder="0" 
                                                allowfullscreen
                                                position="relative"/>
                                      }
                                     {!tutorial.youTubeTutorialEmbeddedVideoUrl &&
                                      <img src={tutorial.mainPromotionalImageUrl} alt="" />
                                     }
                                    </Link>
                                    <div className="card-badge">
                                        <span className="badge-label"><Moment format="D MMM YYYY" withTitle>{tutorial.createdAt != null?
                            tutorial.createdAt : Date().toLocaleString()}</Moment></span>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <h3 className="card__title mt-0"> {tutorial.youTubeTutorialTitle}</h3>
                                    <div className="card-action">
                                        <ul className="card-duration d-flex align-items-center">
                                            <li><span className="la la-user"></span>By<Link to="#" className="blog-admin-name">{author.firstName} {author.lastName}</Link></li>
                                            <li>
                                                <span className="la la-tags"></span>
                                                <Link to="#" className="ml-0">Algorithms,</Link> <Link to="#">Python</Link>
                                            </li>
                                            <li><span className="blog__panel-comment"><span className="la la-commenting"></span>4 Comments</span></li>
                                            <li><span className="blog__panel-likes"><span className="la la-thumbs-up"></span>130 Likes</span></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="widget-title pb-3">Description</h3>
                                       <p>{tutorial.youTubeTutorialDescription}</p>
                                        <h3 className="widget-title pb-3">Content</h3>
                                          <p>{tutorial.introToTutorialSection}</p>
                                           <p>{tutorial.mainContent}</p>
                                        <h3 className="widget-title pb-3">Technical Section</h3>
                                            {tutorial.TutorialStepCodeDetails.map((value,index)=>(
                       
                                            <section key={value.tutorialStepCodeDetailId}>
                                                   <h4 className="widget-title pb-3">{value.sourceCodeSnippetSectionTitle}</h4> 
                                                   <p>{value.sourceCodeSnippetExplanation}</p>
                                             
                                            <PrismCode
                                                code={value.sourceCodeDetail}
                                                language={value.language}
                                                plugins={["line-numbers", "show-language"]}
                                            />
                                
                                            </section>
                   
                                             ))}
                                        <div className="section-block"></div>
                                        <div className="tags-item tag-widget padding-top-30px d-flex align-items-center justify-content-between">
                                            <ul className="list-items">
                                                <li><span>Tags:</span></li>
                                                <li><Link to="#">Algorithms</Link></li>
                                                <li><Link to="#">Python</Link></li>
                                            
                                            </ul>
                                            <ul className="social-profile">
                                                <li><span>Share:</span></li>
                                                <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="author-wrap padding-top-30px">
                                <h3 className="widget-title">About the author</h3>
                                <ul className="comments-list padding-top-30px">
                                    <li>
                                        <div className="comment">
                                            <div className="comment-avatar">
                                                <img className="avatar__img" alt="" src={author.userProfileImageUrl}/>
                                            </div>
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <h3 className="comment__author font-size-24">{author.firstName} {author.lastName}</h3>
                                                
                                                </div>
                                                <p className="comment-content line-height-26">
                                                    {author.userBriefBio}
                                                </p>
                                                <ul className="social-profile">
                                                    
                                                    <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                                    <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="comments-wrapper" hidden>
                                <h3 className="widget-title">4 Comments</h3>
                                <ul className="comments-list padding-top-30px">
                                    <li>
                                        <div className="comment">
                                            <div className="comment-avatar">
                                                <img className="avatar__img" alt="" src="./../styletheme/images/team7.jpg"/>
                                            </div>
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <h3 className="comment__author">adam smith</h3>
                                                    <p className="comment__date">17 Dec, 2018 - 4:00 pm</p>
                                                </div>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                </p>
                                                <div className="comment-reply">
                                                    <Link className="theme-btn" to="#" data-toggle="modal" data-target=".modal-action-form">
                                                        <span className="la la-mail-reply"></span> Reply
                                                    </Link>
                                                    <div className="helpful__action d-flex align-items-center">
                                                        <span className="helpful__action-text">Was this review helpful?</span>
                                                        <button className="btn">Yes</button>
                                                        <button className="btn">No</button>
                                                        <div className="msg-action-dot">
                                                            <div className="dropdown">
                                                                <Link className="action-dot" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="la la-ellipsis-v"></i>
                                                                </Link>
                                                                <div className="dropdown-menu border">
                                                                    <ul>
                                                                        <li><Link className="dropdown-item" to="javascript:void(0)" data-toggle="modal" data-target=".report-modal-form"><i className="la la-flag mr-2"></i>Report abuse</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="comments-reply">
                                            <li>
                                                <div className="comment">
                                                    <div className="comment-avatar">
                                                        <img className="avatar__img" alt="" src="./../styletheme/images/team8.jpg"/>
                                                    </div>
                                                    <div className="comment-body">
                                                        <div className="meta-data">
                                                            <h3 className="comment__author">Jhon doe</h3>
                                                            <p className="comment__date">17 Dec, 2018 - 4:00 pm</p>
                                                        </div>
                                                        <p className="comment-content">
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                            Ut enim ad minim veniam.
                                                        </p>
                                                        <div className="comment-reply">
                                                            <Link className="theme-btn" to="#" data-toggle="modal" data-target=".modal-action-form">
                                                                <span className="la la-mail-reply"></span> Reply
                                                            </Link>
                                                            <div className="helpful__action d-flex align-items-center">
                                                                <span className="helpful__action-text">Was this review helpful?</span>
                                                                <button className="btn">Yes</button>
                                                                <button className="btn">No</button>
                                                                <div className="msg-action-dot">
                                                                    <div className="dropdown">
                                                                        <Link className="action-dot" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <i className="la la-ellipsis-v"></i>
                                                                        </Link>
                                                                        <div className="dropdown-menu border">
                                                                            <ul>
                                                                                <li><Link className="dropdown-item" to="javascript:void(0)" data-toggle="modal" data-target=".report-modal-form"><i className="la la-flag mr-2"></i>Report abuse</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="comment">
                                            <div className="comment-avatar">
                                                <img className="avatar__img" alt="" src="./../styletheme/images/team9.jpg"/>
                                            </div>
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <h3 className="comment__author">Mike Doe</h3>
                                                    <p className="comment__date">17 Dec, 2018 - 4:00 pm</p>
                                                </div>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                </p>
                                                <div className="comment-reply">
                                                    <Link className="theme-btn" to="#" data-toggle="modal" data-target=".modal-action-form">
                                                        <span className="la la-mail-reply"></span> Reply
                                                    </Link>
                                                    <div className="helpful__action d-flex align-items-center">
                                                        <span className="helpful__action-text">Was this review helpful?</span>
                                                        <button className="btn">Yes</button>
                                                        <button className="btn">No</button>
                                                        <div className="msg-action-dot">
                                                            <div className="dropdown">
                                                                <Link className="action-dot" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="la la-ellipsis-v"></i>
                                                                </Link>
                                                                <div className="dropdown-menu border">
                                                                    <ul>
                                                                        <li><Link className="dropdown-item" to="javascript:void(0)" data-toggle="modal" data-target=".report-modal-form"><i className="la la-flag mr-2"></i>Report abuse</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="see-more-review-btn margin-bottom-50px">
                                    <div className="btn-box text-center">
                                        <button type="button" className="theme-btn theme-btn-light">load more comments</button>
                                    </div>
                                </div>
                                <div className="review-form">
                                    <h3 className="widget-title">Add a Comment</h3>
                                    <div className="contact-form-action margin-top-35px">
                                        <form method="post">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input-box">
                                                        <label className="label-text">Name<span className="primary-color-2 ml-1">*</span></label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="name" placeholder="Your name"/>
                                                            <i className="la la-user input-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input-box">
                                                        <label className="label-text">Email<span className="primary-color-2 ml-1">*</span></label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="email" name="email" placeholder="Email address"/>
                                                            <i className="la la-envelope-o input-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="input-box">
                                                        <label className="label-text">Message<span className="primary-color-2 ml-1">*</span></label>
                                                        <div className="form-group">
                                                            <textarea className="message-control form-control" name="message" placeholder="Write message"></textarea>
                                                            <i className="la la-pencil input-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="custom-checkbox">
                                                            <input type="checkbox" id="chb1"/>
                                                            <label htmlFor="chb1">Save my name, and email in this browser for the next time I comment.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="btn-box">
                                                        <button className="theme-btn" type="submit">submit comment</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar">
                       
                                <div className="sidebar-widget recent-widget">
                                    <h3 className="widget-title">Latest Tutorials</h3>
                                    <span className="section-divider"></span>

                                       {latestTutorials.map((lValue,index)=>(
                                    <div className="recent-item" key={lValue.technicalTutorialId}>
                                        <div className="recent-img">
                                            <Link to={`/tutorial/${lValue.youTubeTutorialTitle}`}>
                                                <img src={lValue.mainPromotionalImageUrl} alt="tutorial image"/>
                                            </Link>
                                        </div>
                                        <div className="recentpost-body"> 
                                            
                                            <h4 className="recent__link">
                                                <Link to={`/tutorial/${lValue.youTubeTutorialTitle}`}>{lValue.youTubeTutorialTitle}</Link>
                                            </h4>
                                            <span className="recent__meta">Posted: <Moment format="D MMM YYYY" withTitle>{lValue.createdAt != null?
                            lValue.createdAt : Date().toLocaleString()}</Moment></span>
                                        </div>
                                    </div>
                                         ))}
                              
                                    <div className="btn-box text-center">
                                        <Link to="/tutorials" className="theme-btn d-block">view all tutorials</Link>
                                    </div>
                                </div>
                    
                                <div className="sidebar-widget tag-widget">
                                    <h3 className="widget-title">Tutorial Tags</h3>
                                    <span className="section-divider"></span>
                                    <ul className="list-items">
                                        <li><Link to="#">Algoirthms</Link></li>
                                        <li><Link to="#">Python</Link></li>
                                
                                    </ul>
                                </div>
                                <div className="sidebar-widget">
                                    <h3 className="widget-title">Subscribe</h3>
                                    <span className="section-divider"></span>
                                    <div className="contact-form-action">
                                        <form onSubmit={this.handleSubmit} className="contact-form-action">
                                            <div className="form-group">
                                             
                                                {this.renderNoLabelInput("emailAddress","email","form-control","Email Address","text-secondary",false)}
                                               
                                                {this.renderArrowButton("submit-btn")}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="sidebar-widget">
                                    <h3 className="widget-title">Connect  Follow</h3>
                                    <span className="section-divider"></span>
                                    <ul className="social-profile">
                                        <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-google-plus"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         );
    }
}
 
export default Tutorial;
