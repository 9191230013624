import React, { Component } from 'react'
import { Link } from 'react-router-dom';


class RecoverPassword extends Component {
    state = {  }
    render() { 
        return (  
            <section className="recover-area " style={{"paddingTop": "3%"}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-7 mx-auto">
                <div className="card-box-shared">
                    <div className="card-box-shared-title">
                        <h3 className="widget-title font-size-35 pb-2">Reset Password! (COMING SOON)</h3>
                        <p className="line-height-26">
                            Enter the email of your account to reset password.
                            Then you will receive a link to email to reset the
                            password.If you have any issue about reset password <Link to="/contactus" className="primary-color-2">contact us</Link>
                        </p>
                    </div>
                    <div className="card-box-shared-body">
                        <div className="contact-form-action">
                            <form method="post">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <label className="label-text">Email Address<span className="primary-color-2 ml-1">*</span></label>
                                            <div className="form-group">
                                                <input className="form-control" type="email" name="email" placeholder="Enter email address"/>
                                                <span className="la la-envelope input-icon"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <button className="theme-btn" disabled={true} type="submit">reset password</button>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <p><Link to="/login" className="primary-color-2">Login</Link></p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="text-right register-text">Not a member? <Link to="/register" className="primary-color-2">Register</Link></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        );
    }
}
 
export default RecoverPassword;