import React, { Component } from 'react'

class CheckerQuestionArea extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                 <h3>A card showing all the checker questions answered and the total questions given and the current score
                     ,May show correctness
                 </h3>
                <h3>Current Active Checker Question link placed here</h3>
                 <h3>Show all past checker questions section below</h3>
                
            </div>
         );
    }
}
 
export default CheckerQuestionArea;