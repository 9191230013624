import React, { Component } from 'react';
import {NavLink,Link} from 'react-router-dom';
import logo from '../../logo/logo@3x.png';

const NavBar = ({student,currentProfile}) => {
 //On load get the profile info from me

    
        return ( 
   
  
         <div> 
<div className="header-menu-area">
    <div className="header-menu-fluid">
        {!student &&
        //Anonymous users
            <React.Fragment>
        <div className="header-top">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="header-widget header-widget1">
                            <ul className="contact-info d-flex align-items-center">
                              
                                <li><Link to="/"><span className="la la-envelope-o"></span> vengarre@vengarre.com</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="header-widget header-widget2 d-flex align-items-center justify-content-end">
                            <div className="header-right-info">
                                <ul className="social-profile d-flex align-items-center">
                                    <li><a to="#"><i className="fa fa-facebook"></i></a></li>
                                    <li><a to="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a to="#"><i className="fa fa-instagram"></i></a></li>
                                    <li><a to="#"><i className="fa fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <div className="header-right-info d-flex align-items-center">
                              
                          
                                <ul className="user-action d-flex align-items-center">
                                    <li><NavLink to="/login">Login</NavLink></li>
                                   

                         
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>

        }
        <div className="header-menu-content header-top">
            <div className="container-fluid">
                <div className="main-menu-content">
                    <div className="row align-items-center h-100">
                        <div className="col-lg-3">
                            <div className="logo-box">
                                
                                <Link to="/" className="logo" title="Vengarre LMS"><img src={logo} style={{height: "40%",width: "40%"}} alt="Vengarre logo"/></Link>
                     
                            </div>
                        </div>
                    {student &&
                    //logged in users
                        <React.Fragment>
                            <div className="col-lg-9">
                                <div className="menu-wrapper">
                                
                                    <nav className="main-menu">
                                        <ul>
                                            <li>
                                                <NavLink className="nav-link" to="/dashboard">Dashboard</NavLink>
                                            </li>
                                             <li>
                                             <NavLink className="nav-link" to="/mycourses">My Courses</NavLink>
                                            </li>
                                            <li>
                                             <NavLink className="nav-link" to="/coursediscussions">Course Discussions</NavLink>
                                            </li>
                                            <li>
                                                 <NavLink className="nav-link" to="/logout"> Log Out</NavLink>
                                            </li>
                                            <li>
                                                {currentProfile &&
                                                <p>Welcome {currentProfile[0].firstName} {currentProfile[0].lastName}!</p>
                                                }
                                            </li>
                                    
                                            
                                        </ul>
                                    </nav>
                                 
                                    <div className="side-nav-container">
                                        <div className="humburger-menu">
                                            <div className="humburger-menu-lines side-menu-close"></div>
                                        </div>
                                        <div className="side-menu-wrap">
                                            <ul className="side-menu-ul">
                                                <li className="sidenav__item">
                                                    <NavLink className="nav-link" to="/dashboard">Dashboard</NavLink>
                                                </li>
                                                <li className="sidenav__item">
                                                    <NavLink className="nav-link" to="/coursediscussions">Course Discussions</NavLink>
                                                </li>
                                                <li className="sidenav__item">
                                                     <NavLink className="nav-link" to="/logout"> Log Out</NavLink>
                                                </li>
                                             
                                            </ul>
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                        {!student &&
                        //anonymous
                        <React.Fragment>
                            <div className="col-lg-9">
                                <div className="menu-wrapper">
                                
                                    <nav className="main-menu">
                                        <ul>
                                            <li>
                                            <NavLink  aria-current="page" to="/">Home</NavLink>
                                            </li>
                                            <li>
                                            <NavLink  to="/tutorials">Tutorials</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/courses">Courses</NavLink>
                                            </li>
                                    
                                            <li>
                                                <NavLink to="/contactus">Contact</NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="logo-right-button">
                                        <ul>
                                            <li>
                                            <NavLink className="theme-btn" to="/register">Register</NavLink>
                                            </li>
                                        </ul>
                                        <div className="side-menu-open">
                                            <i className="la la-bars"></i>
                                        </div>
                                    </div>
                                    <div className="side-nav-container">
                                        <div className="humburger-menu">
                                            <div className="humburger-menu-lines side-menu-close"></div>
                                        </div>
                                        <div className="side-menu-wrap">
                                            <ul className="side-menu-ul">
                                                <li className="sidenav__item">
                                                    <NavLink  aria-current="page" to="/">Home</NavLink>
                                                </li>
                                                <li className="sidenav__item">
                                                    <NavLink  to="/tutorials">Tutorials</NavLink>
                                                </li>
                                                <li className="sidenav__item">
                                                    <NavLink to="/courses">Courses</NavLink>
                                                </li>
                                                <li className="sidenav__item">
                                                    <NavLink to="/contactus">Contact</NavLink>
                                                </li>
                                                <li className="sidenav__item">
                                                    <NavLink to="/contactus">contact</NavLink>
                                                </li>
                                            </ul>
                                            <div className="side-btn-box">
                                            
                                                <NavLink className="theme-btn" to="/login">Login</NavLink>
                                                <span>or</span>
                                                <NavLink className="theme-btn" to="/register">Register</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

         );
    
}
 
export default NavBar;