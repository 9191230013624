import React, { Component } from 'react'
import {getCurrentUserProfile} from '../../../services/students/studentauthen';
import { fetchAllCoursesStudentEnrolledInById } from '../../../services/courses/coursesService';
import MyCourseCard from '../../common/cards/mycoursecard';

class MyCourses extends Component {
    state = {  
        studentProfile:{
            firstName:'',
            lastName:'',
            emailAddress:'',
            studentId:'',
        },
        enrolledCourses:[{
           
            userId:'',
        

            CourseOverviews:[{
                courseId:'',
                courseTitle:'',
                courseDescription:'',
                coursePromoImageUrl:'',
            },]
        }]
    }
    async componentDidMount(){
        const {data: student} = await getCurrentUserProfile();

        console.log(student.studentId);
        //fetch all courses enrollment by student ID
        const {data: enrolledCourses} = await fetchAllCoursesStudentEnrolledInById(student.studentId);
        console.log(enrolledCourses);

        this.setState({enrolledCourses});
        //then course -> module -> dicussions etc
       
    }

    render() { 
        const {enrolledCourses} = this.state;
        return ( 
            <div>
                <h4>My Courses will show up here</h4>
                    <ul>
                         {enrolledCourses.map((value,index)=>(
                        //hide the Quick Links here show the class stats
                                <div key={index}>

                                 
                                  <MyCourseCard name="courses"
                                    courseId ={value["CourseOverviews.courseId"]}
                                    authorName={value.firstName + " " + value.lastName}
                                    question = {value["CourseOverviews.courseTitle"]}
                                    discussionDetail={value["CourseOverviews.courseDescription"]}
                                    title={value.discussionTitle}
                                    mediaUrl ={ value["CourseOverviews.coursePromoImageUrl"]}
                                    timeStamp={value.dueDateTimeStampStr}
                                    courseCost={value["CourseOverviews.coursePrice"]}
                                    hasQuickLinks={false}
                                    showPrice={false}
                                    showAuthor={true}
                                    destination={'coursemodules'}
                                 />
                                
                       </div>
                            ))}
                </ul>
            </div>
         );
    }
}
 
export default MyCourses;

