//show the discussions, lectures and checker questions for this target module
//Next
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class CourseModuleArea extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                 <section>
                    <h4>Active Class Session List</h4>

                </section>
                <section>
                    <h4>Checker Questions</h4>
                    <p><Link to="/activecheckerquestion" className="primary-color-2">Active Checker Question</Link></p>
                </section>
                  <section>
                    <h4>Discussions</h4>
                    
                </section>
                  <section>
                    <h4>Lectures</h4>
                    
                </section>
                 

            </div>
         );
    }
}
 
export default CourseModuleArea;