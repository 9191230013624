import React, { Component } from 'react'
import CourseCard from '../common/cards/coursecard';
import { fetchAllTutorials } from '../../services/tutorials/tutorialService';

class Tutorials extends Component {
    state = { 
        tutorials: [{
            youTubeTutorialTitle: '',
            youTubeTutorialEmbeddedVideoUrl: '',
            youTubeTutorialDescription: '',
            introToTutorialSection: '',
            mainContent: '',
            mainPromotionalImageUrl: '',
        }]
     }
  

     async componentDidMount() {
        //promise will complete in the future or in pending then resolved (success) or rejected if fail
        //wait till the promised results appears then extract the values
        //get data and rename it to courses (the object name)
        //get all discussion by the module id
        const {data: tutorials} = await fetchAllTutorials();
        //const result = Object.entries(discussions);
        console.log( tutorials);
        //update the courses by setting the state
        this.setState({tutorials});

    }

    render() { 

        const {tutorials} = this.state;
        return ( 
            <div>
                <h3>Vengarre Tutorials</h3>
                <br/>
     
                <div role="tabpanel" class="tab-pane fade show active" id="grid-view" aria-labelledby="grid-view-tab">
                    <div class="row" >
                            {tutorials.map((value,index)=>(
                       
                                

                                 
                                  <CourseCard authorName="Vengarre"
                                    key={index}
                                    description = {value.youTubeTutorialDescription}
                                    title={value.youTubeTutorialTitle}
                                    mediaUrl ={ value.mainPromotionalImageUrl}
                                    path= {`/tutorial/${value.youTubeTutorialTitle}`}
                                 />
                                
                       
                   
                            ))}
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Tutorials;