import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import "https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap";
import "../src/styletheme/css/bootstrap.min.css";
import "../src/styletheme/css/font-awesome.min.css";
import "../src/styletheme/css/line-awesome.css";
import "../src/styletheme/css/animate.min.css";
import "../src/styletheme/css/owl.carousel.min.css";

import "../src/styletheme/css/owl.theme.default.min.css";
import "../src/styletheme/css/bootstrap-select.min.css";
import "../src/styletheme/css/magnific-popup.css";
import "../src/styletheme/css/fancybox.css";
import "../src/styletheme/css/jquery.filer.css";
import "../src/styletheme/css/tooltipster.bundle.css";
import "../src/styletheme/css/jqvmap.css";
import "../src/styletheme/css/style.css";

 
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import StudentRegister from './components/authentication/registerstudent';
import StudentLogin from './components/authentication/login';
import {BrowserRouter} from 'react-router-dom';
//import app from 'firebase/app';

//registering firebase
/*const firebaseConfig = {
  apiKey: "AIzaSyAnVKRgZuqpx13IK4qEfFQ4I-sjcws6ZHQ",
  authDomain: "vengarreweb-b5979.firebaseapp.com",
  databaseURL: "https://vengarreweb-b5979.firebaseio.com",
  projectId: "vengarreweb-b5979",
  storageBucket: "vengarreweb-b5979.appspot.com",
  messagingSenderId: "313651182597",
  appId: "1:313651182597:web:6785bd54c7b74ebb67f06c"
};
app.initialize(firebaseConfig);//meed this later for file uploads*/
//main js file

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />

    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

