import React, { Component } from 'react'

class CoursePage extends Component {
    state = {  }
    render() { 
        return (  
            <div>
                <h3>Courses Page</h3>
            </div>
        );
    }
}
 
export default CoursePage;