import http from '../httpService';
import {apiEndpoint} from '../../constants/config.json';
import jwtDecode from 'jwt-decode';

//declaring the constants
const tokenKey = "v_lms_token";

http.setJwt(getJwt());

//TODO implement the nodejs authenication methods using mosh nodejs course
export function registerNewStudent(student,courseCurriculumId){
    const fullApiEndpoint = apiEndpoint + "/authentication/api/create_new_student_profile";
    //need to write to firebase then the server
   // console.log("About to send out curric info");
   // console.log(courseCurriculumId);

    return http.post(fullApiEndpoint,{
        firstName: student.firstName,
        lastName: student.lastName,
        emailAddress: student.emailAddress,
        studentIdNumber: student.studentIdNumber,
        studentAccessCode: student.studentAccessCode,
        studentImageUrl: student.studentImageUrl,
        passwordTxt: student.password,
        recordTimeStamp: Date().toLocaleString(),//student.recordTimeStamp,
        recordUpdatedTimeStamp: Date().toLocaleString(),//student.recordUpdatedTimeStamp,
        isDeleted: false,
        isCourseFree: student.isCourseFree,
        isPaid: student.isPaid,
        courseId: student.courseId,//neeed to come from the interface
        courseCurriculumId: courseCurriculumId,

    });
}

//login method
//http://localhost:3001/authentication/api/login_student
export async function authLoginStudent(accountPayLoad){
    const fullApiEndpoint = apiEndpoint + "/authentication/api/login_student";
    //emailAddress
    //passwordTxt
    const {data: jwt} = await http.post(fullApiEndpoint,{
        emailAddress: accountPayLoad.emailAddress,
        passwordTxt: accountPayLoad.passwordTxt
    });//need to get the response

     localStorage.setItem(tokenKey,jwt);

}

export function loginWithJwt(jwt){
    localStorage.setItem(tokenKey,jwt);

}

export function logout(){
     localStorage.removeItem(tokenKey);
}

export function getCurrentUser(){
     try{
      //get current user
      const jwt = localStorage.getItem(tokenKey);
      return jwtDecode(jwt);
 
    }
    catch(ex) { 
        return null;
    }


}

export function getJwt(){
    return localStorage.getItem(tokenKey);
}

//get current user profile details

export function getCurrentUserProfile(){
    const fullApiEndpoint = apiEndpoint + "/authentication/api/me";
    return http.get(fullApiEndpoint);
}