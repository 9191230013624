import React, { Component } from 'react';
import {NavLink,Link} from 'react-router-dom';
import logo from '../../../logo/logo@3x.png';
import ScrollToTop from '../../common/scrolltotop';

const GeneralFooter = () => {
    return ( 
        <React.Fragment>

            <section class="footer-area section-bg-2 padding-top-100px padding-bottom-40px">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 column-td-half">
                            <div class="footer-widget">
                                <Link to="/">
                                    <img src={logo} style={{height: "70%",width: "70%"}} alt="Vengarre logo" class="footer__logo"/>
                                </Link>
                                <ul class="list-items footer-address">
                                    <li>With Vengarre in your corner, we will turn those complexities into measurable results.</li>
                                    <li><Link to="#" class="mail">vengarre@vengarre.com</Link></li>
                                    <li>Maine, USA</li>
                                </ul>
                                <h3 class="widget-title font-size-17 mt-4">We are on</h3>
                                <ul class="social-profile">
                                
                                    <li><Link to="#"><i class="fa fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fa fa-linkedin"></i></Link></li>
                                
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 column-td-half">
                            <div class="footer-widget">
                                <h3 class="widget-title">Company</h3>
                                <span class="section-divider"></span>
                                <ul class="list-items">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/tutorials">Tutorials</Link></li>
                                    <li><Link to="/courses">Courses</Link></li>
                                    <li><Link to="/contactus">Contact</Link></li>
                                    <li><Link to="/register">Register</Link></li>
                                    <li><Link to="/login">Login</Link></li>
                                </ul>
                            </div>
                        </div>
                       
                        <div class="col-lg-3 column-td-half">
                            <div class="footer-widget">
                                <h3 class="widget-title">Download App</h3>
                                <span class="section-divider"></span>
                                <ul class="btn-box">
                                    <li>
                                        <Link to="#" class="theme-btn">
                                            <i class="la la-apple icon-element"></i>
                                            <span class="app-titles">
                                                <span class="app__subtitle">Download on the</span>
                                                <span class="app__title">App Store (Coming Soon)</span>
                                            </span>
                                        </Link>
                                        
                                    </li>
                                    <li>
                                        <Link to="#" class="theme-btn">
                                            <i class="la la-android icon-element"></i>
                                            <span class="app-titles">
                                                <span class="app__subtitle">Get in on</span>
                                                <span class="app__title">Google Play (Coming Soon)</span>
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="copyright-content">
                        <div class="row align-items-center">
                            <div class="col-lg-10">
                                <p class="copy__desc">&copy; {(new Date().getFullYear())} Vengarre. All Rights Reserved. by 
                                <Link to={{ pathname: "https://vengarre.com/" }} target="_blank" > Vengarre</Link>
                                </p>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </section>
 <ScrollToTop>
            <div id="scroll-top">
                <i class="fa fa-angle-up" title="Go top"></i>
            </div>
</ScrollToTop>
    </React.Fragment>

     );
}
 
export default GeneralFooter;