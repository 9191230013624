import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Moment from 'react-moment';

const MyCourseCard  = ({timeStamp,question,authorName,discussionDetail,mediaUrl,title,courseId,courseCost,hasQuickLinks,showPrice,showAuthor,destination}) => {
    let defaultImage = "https://firebasestorage.googleapis.com/v0/b/vengarreweb-b5979.appspot.com/o/vengarreUserAuthorImages%2FAuthor_Image_155346228884902.png?alt=media&token=4caba676-7e1c-4ceb-b447-7500991d2e88";

    return (   
        

                       <div className="card-item card-list-layout">
                           {mediaUrl &&
                                <div className="card-image">
                                    <Link to={`/courseModules/${courseId}`} className="card__img"><img src={mediaUrl} alt=""/></Link>
                                </div>
                            }
                                <div className="card-content">
                                    <h3 className="card__title mt-0">{/* TODO NEXT SET UP MODULES SCREEN coursemodulearea  coursemodules*/}
                                        <Link to={`/${destination}/${courseId}`}>{question}</Link>
                                    </h3>
                                    {showAuthor &&
                                        <p className="card__author">
                                            <a to="#">by {authorName}</a>
                                        </p>
                                    }
                                    <div className="card-action">
                                        <ul className="card-duration d-flex">
                                            <li>
                                                <span className="meta__date mr-2">
                                                    <span className="status-text">Status:</span>
                                                    <span className="badge bg-success text-white">Published</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="meta__date mr-2">
                                                    <span className="status-text">Duration:</span>
                                                    <span className="status-text primary-color-3">N/A</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="meta__date mr-2">
                                                    <span className="status-text">Students:</span>
                                                    <span className="status-text primary-color-3">N/A</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-price-wrap d-flex align-items-center">
                                        {showPrice &&
                                            <p className="card__price">${courseCost}</p>
                                         }
                                        <div className="edit-action">
                                           {hasQuickLinks &&
                                           <React.Fragment>
                                                <span className="status-text primary-color-3">Quick Links</span>
                                                <ul className="edit-list">{/*(based on which is open)(based on which is open), only one discussion can be opened at a time in a course*/}
                                                    <li><Link  to={`/coursediscussions/${courseId}`} className="theme-btn view-btn"><i className="la la-eye mr-1 font-size-16"></i>Discussions</Link></li>
                                                {/* based on the currently opened module stored in Student Enrollment info*/}
                                                <li><Link to= {`/checkerquestions/${courseId}`} className="theme-btn edit-btn"><i className="la la-eye mr-1 font-size-16"></i>Checker Questions</Link></li>
                                                    <li><Link to={`/lectures/${courseId}`} className="theme-btn info-btn" data-toggle="modal" data-target=".item-delete-modal"><i className="la la-eye mr-1 font-size-16"></i>Lectures</Link></li>
                                                </ul>
                                            </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
            
          
           
        );
    }

 
export default MyCourseCard;




