import React, { Component } from 'react'

class PastCheckerQuestions extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                <h3>All past checker questions can be found here</h3>
                
            </div>
         );
    }
}
 
export default PastCheckerQuestions;