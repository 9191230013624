import React, { Component } from 'react'
import Joi from 'joi-browser';
import Form from '../common/form';
import {authLoginStudent,getCurrentUser} from '../../services/students/studentauthen';
import { Redirect,Link } from 'react-router-dom';
import CourseCard from '../common/cards/coursecard';
import http from '../../services/httpService';
import config from '../../constants/config.json';
import { fetchLatestThreeTutorials } from '../../services/tutorials/tutorialService';

class HomePage extends Form {
       state = {
        data:{
            emailAddress: '',
            password: ''
        },
        tutorials: [{
            youTubeTutorialTitle: '',
            youTubeTutorialEmbeddedVideoUrl: '',
            youTubeTutorialDescription: '',
            introToTutorialSection: '',
            mainContent: '',
            mainPromotionalImageUrl: '',
        }],
        errors:{
           
        }
    };
    //Joi validation schema
    schema ={
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'edu','edu.jm'] } }).required().label('Email Address'),//use joi in node as well
        password: Joi.string().required().label('Password')
    }
 

    async componentDidMount(){
        const {data: tutorials} = await fetchLatestThreeTutorials();//http.get(config.apiEndpoint +'/tutorials/api/get_latest_three_tutorial_blogs');
        //const result = Object.entries(discussions);
        console.log( tutorials);
        //update the courses by setting the state
        this.setState({tutorials});
    }

    doSubmit = async () =>{
        //login function
        try{
            const {data} = this.state;
            const accountPayLoad ={
                emailAddress: data.emailAddress,
                passwordTxt: data.password
            }
            
            await authLoginStudent(accountPayLoad);

            //get the current location
            const {state} = this.props.location; 
           
            //force a reload of the page
            window.location = state ? state.from.pathname : "/dashboard";

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors ={...this.state.errors};
                errors.emailAddress = ex.response.data;
                //Update the UI
                this.setState({errors});
            }
        }
    }
    render() { 
         const {tutorials} = this.state;
        return ( 
          <section className="slider-area slider-area3" >
    <div className="homepage-slide2" style={{"min-height": "15%","maxHeight":"40%"}}>
        <div className="single-slide-item slide-bg1" >
            <div className="slide-item-table" >
                <div className="slide-item-tablecell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-heading mr-4">
                                    <div className="section-heading">
                                        <h2 className="section__title">Take the First Step of Your Journey to Success</h2>
                                        <p className="section__desc line-height-30">
                                            Who tells you can’t do it? Build the confidence, skills, and qualifications to take control of your future.
                                            Start one of our 1000 high quality courses from the world’s leading experts today!
                                        </p>
                                    </div>
                                    <div className="hero-search-form">
                                        <div className="contact-form-action">
                                            <form method="post">
                                                <div className="input-box">
                                                    <label className="label-text section__meta section__metalight">What do you want to learn?</label>
                                                    <div className="form-group mb-0">
                                                        <input className="form-control" type="text" name="search" placeholder="Search courses by keywords"/>
                                                        <span className="la la-search search-icon"></span>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 card">
                                <div class="card-box-shared-title text-center">
                                <h3 class="widget-title font-size-35">Student - Log In</h3>
                            </div>
                                <br/>
                                <form onSubmit={this.handleSubmit} className="contact-form-action"> 
                                    <div className="col-lg-12">
                                        <div className="input-box ">
                                        {this.renderTextInputLabel("emailAddress","Email Address","text","form-control","Email Address","label-text",true,"la la-envelope input-icon")}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                
                                        {this.renderTextInputLabel("password","Password","password","form-control","Password","label-text",true,"la la-key input-icon")}
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <div class="custom-checkbox d-flex justify-content-between">
                                                
                                                <Link to="/recoverpassword" class="primary-color-2"> Forgot my password?</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 ">
                                        <div className="btn-box">
                                        {this.renderButton('Login',"theme-btn")} 
                                        </div>
                                    </div>
                                     <div class="col-lg-12">
                                        <p class="mt-4 text-secondary">Don't have an account? <Link to="/register" class="primary-color-2">Register</Link></p>
                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center">
                        <h5 className="section__meta">Choose your desired tutorials</h5>
                        <h2 className="section__title">Browse Our Tutorials</h2>
                        <span className="section-divider"></span>
                    </div>
                </div>
            </div>
            <div className="row">
                     <div role="tabpanel" class="tab-pane fade show active" id="grid-view" aria-labelledby="grid-view-tab">
                    <div class="row" >
                            {tutorials.map((value,index)=>(
                       
                                

                                 
                                  <CourseCard authorName="Vengarre"
                                    key={index}
                                    description = {value.youTubeTutorialDescription}
                                    title={value.youTubeTutorialTitle}
                                    mediaUrl ={ value.mainPromotionalImageUrl}
                                    path= {`/tutorial/${value.youTubeTutorialTitle}`}
                                 />
                                
                       
                   
                            ))}
                    </div>
                </div>
            </div>

        </div>
    </section>
         );
    }
}
 
export default HomePage;


